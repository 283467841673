import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const Homesection = () => {
  return (
    <Box
      sx={{
        p: { xs: "2rem", md: "4rem" },
      }}
      minHeight={350}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      position={"relative"}
      overflow={"hidden"}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontSize: { xs: "1.3rem", sm: "1.2rem", md: "1.6rem" },
        }}
      >
        “ We can only see a short distance ahead, but we can see plenty there that needs to be done."
      </Typography>
      <Typography
        display={{ xs: "none", sm: "none", md: "flex" }}
        fontSize="1.2rem"
        fontWeight={500}
        color="#747474"
        mt={"2rem"}
        textAlign={"center"}
      >
        - ALAN TURING
      </Typography>
      <Box
        position={"absolute"}
        sx={{
          clipPath: "polygon(0 100%, 0 55%, 100% 100%)",
          height: "150%",
          width: "58%",
          left: 0,
          bottom: "0%",
          backgroundColor: "#f5f7f9",
          zIndex: "-1",
        }}
      />
      <Box
        position={"absolute"}
        sx={{
          clipPath: "polygon(0 100%, 100% 62%, 100% 100%)",
          height: "180%",
          width: "55%",
          right: 0,
          bottom: "0%",
          backgroundColor: "#f5f7f9",
          zIndex: "-1",
        }}
      />
    </Box>
  );
};

export default Homesection;
