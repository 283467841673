import React from "react";
import Layout from "./../components/Layout/Layout";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import aboutBackground from "../images/about_background.png";
import teamManagement from "../images/team_management1.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";

const cardList = [
  // {
  //   name: "Santosh Kumar",
  //   designation: "Software Engineer",
  //   department: "Design Team",
  //   img: aboutBackground,
  // },
  // {
  //   name: "Santosh Kumar",
  //   designation: "Software Engineer",
  //   department: "Engineering Team",
  //   img: aboutBackground,
  // },
  {
    name: "Impana ",
    designation: "Director",
    department: "Product Team",
    img: aboutBackground,
  },
];
const About = () => {
  const handleClick = () => {
    window.scrollTo({ top: 600, behavior: "smooth" });
  };
  return (
    <Layout>
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            p: { xs: 8, sm: 15 },
            background: `url(${aboutBackground}) center center no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: "white",
            height: "calc(100vh - 60px)",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "#000",
              opacity: "0.7",
            }}
          ></Box>
          <Typography
            sx={{
              position: "absolute",
              fontSize: "3rem",
              textTransform: "uppercase",
              fontWeight: "600",
            }}
          >
            Who We Are
          </Typography>
          <Box
            sx={{
              position: "absolute",
              bottom: "1rem",
            }}
          >
            <KeyboardArrowDownIcon
              onClick={handleClick}
              className="downArrowButton"
              sx={{
                fontSize: "4rem",
                cursor: "pointer",
                fontWeight: "300",
                border: "1px solid gray",
                borderRadius: "0.8rem",
                ":hover": {
                  opacity: "0.5",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sx={{ p: { xs: 2, sm: 8 } }} backgroundColor="#f5f7f9">
          <Stack display="flex" alignItems="center" justifyContent="center">
            <Typography fontWeight="300" fontSize="32px" color="black">
              A Bit About Us
            </Typography>
            <Divider
              sx={{
                width: "250px",
                mt: "0.5rem",
                borderColor: "#ff4530",
                borderWidth: "2px",
                borderRadius: "4px",
              }}
            />
          <Typography
      mt={5}
      color="#747474"
      sx={{
        fontSize: "1.1rem",
        textAlign: "justify",
        lineHeight: "1.7rem",
      }}
    >
     At CerebraWave, we are passionate about leveraging cutting-edge technology to drive innovation and empower businesses. Founded on the principles of excellence and expertise, we specialize in software development consulting with a focus on Artificial Intelligence (AI) and Data Science.
      <br />
      <br />
      Our team of dedicated professionals brings together a wealth of experience and knowledge to deliver tailored solutions that meet the unique needs of each client. Whether you're a startup looking to harness the power of AI or an established enterprise seeking to optimize your data strategy, we're here to guide you every step of the way.
      <br />
      <br />
      In addition to our consulting services, we offer comprehensive corporate training programs designed to equip your team with the skills and knowledge they need to thrive in today's rapidly evolving tech landscape. From introductory courses to advanced workshops, we provide hands-on training that empowers your workforce to drive innovation and stay ahead of the curve.
      <br/>
      <br/>
      At CerebraWave, we believe that the future belongs to those who embrace change and harness the power of technology. Join us on this journey as we explore new possibilities, solve complex challenges, and redefine what's possible in the world of AI and data science.
    </Typography>
            {/* <Box
              sx={{
                display: "flex",
                gap: "1.5rem",
                flexWrap: "wrap",
                mt: "3.5rem",
              }}
            >
              {cardList.map((item) => (
                <Box
                  sx={{
                    border: "1px solid #c4c4c4",
                    p: "0.5rem",
                    borderRadius: "4px",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                >
                  <img
                    src={item.img}
                    alt="products"
                    style={{
                      width: "300px",
                      height: "300px",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.1rem",
                      m: "0.2rem 0",
                      color: "#3d3d3d",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      color: "#3d3d3d",
                      fontWeight: "600",
                    }}
                  >
                    {item.designation}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      color: "#3d3d3d",
                      fontWeight: "600",
                    }}
                  >
                    {item.department}
                  </Typography>
                </Box>
              ))}
            </Box> */}
          </Stack>
        </Grid>
        <Parallax bgImage={teamManagement} strength={400}>
          <Grid
            item
            xs={12}
            position={"relative"}
            width="100vw"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              height: { xs: "100vh", md: "70vh", lg: "70vh" },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                background: "black",
                opacity: "0.8",
              }}
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection={"column"}
              position={"absolute"}
              textAlign={"center"}
              p="4rem"
            >
              <Typography
                fontWeight="300"
                sx={{ textAlign: { xs: "center", sm: "justify" } }}
                fontSize="2rem"
                color="white"
              >
                The Management Team
              </Typography>
              <Divider
                sx={{
                  width: "380px",
                  mt: "0.5rem",
                  borderColor: "#ff4530",
                  borderWidth: "2px",
                  borderRadius: "4px",
                }}
              />
              <Typography
                mt={5}
                color="#fff"
                sx={{
                  fontSize: "17px",
                  textAlign: { xs: "flex", sm: "justify" },
                  lineHeight: "35px",
                }}
              >
               At CerebraWaves™, our mission is to enhance your business's efficiency, predictability, and effectiveness. We specialize in transforming complex challenges into actionable solutions by harnessing disruptive technologies, thereby providing you with a strategic competitive advantage. Our clientele spans various industries, including Banking & Finance, Retail, E-commerce, Healthcare, Logistics, and more. With our global reach and expertise, we have the capability to deliver tailored solutions to businesses of all sizes across the globe.
              </Typography>
            </Box>
          </Grid>
        </Parallax>
        <Grid
          item
          xs={12}
          sx={{ p: { xs: 2, sm: 10 }, direction: { xs: "column", sm: "row" } }}
          backgroundColor="#f5f7f9"
        >
          <Stack
            height={"50vh"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: { xs: "60vh", md: "70vh", lg: "70vh" },
            }}
          >
            <Typography
              fontWeight="300"
              sx={{ textAlign: { xs: "center", sm: "justify" } }}
              fontSize="32px"
              color="black"
            >
              WORKING WITH US
            </Typography>
            <Divider
              sx={{
                width: "320px",
                mt: "0.5rem",
                borderColor: "#ff4530",
                borderWidth: "2px",
                borderRadius: "4px",
              }}
            />
            <Typography
              mt={5}
              color="#747474"
              sx={{
                fontSize: "17px",
                textAlign: "center",
                lineHeight: "35px",
                maxWidth: { md: "40vw", xs: "70vw", lg: "40vw" },
              }}
            >
              Got an idea? Let us know about your project ideas and find out how
              <strong> CerebraWave</strong> can help your ideas come true.
            </Typography>
            <Link
              to="/contact"
              style={{
                textDecoration: "none",
                background: "#036fcb",
                padding: "0.6rem 1.2rem",
                color: "white",
                marginTop: "1rem",
              }}
            >
              GET IN TOUCH
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default About;
