import { Box } from "@mui/material";
import React from "react";

const PageLayout = ({ styleProps, children, props }) => {
  return (
    <Box sx={styleProps} {...props}>
      {children}
    </Box>
  );
};

export default PageLayout;
