import React from "react";
import Layout from "./../components/Layout/Layout";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import contact from "../images/contact_us.jpg";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <Layout>
      <Grid container>
        <Box
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: `url(${contact}) center center no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            height: "20vh",
            width: "100vw",
          }}
        >
          <Typography textTransform={"uppercase"} fontSize="28px">
            Contact Us
          </Typography>
        </Box>

        <Grid item xs={12} md={6} sx={{ p: { sm: 10, xs: 2 } }}>
          <Stack>
            <Typography
              sx={{ fontSize: "1rem", mt: "2rem" }}
              color="#777"
              className="contact"
            >
              Need to get in touch with us? Either fill out the form with your
              enquiry or find the Department Email you'd like to contact below.
            </Typography>
          </Stack>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              mt: 5,
            }}
          >
            <Box>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
              >
                <LocationOnOutlinedIcon
                  sx={{
                    color: "#fff",
                    fontSize: "1.7rem",
                    background: "#fa0251",
                    p: "4px",
                    borderRadius: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "#222",
                  }}
                >
                  Address :
                </Typography>
              </Box>
              <Typography
                sx={{
                  ml: "0.3rem",
                  mt: "0.4rem",
                  fontSize: "1rem",
                }}
                className="contact"
              >
                No. 313, 2nd floor , Atlantis liberty Square Building, 6th
                stage, Banashankari, Bengaluru, Karnataka, 560062 (India).
              </Typography>
            </Box>
            {/* <Box>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
              >
                <PermPhoneMsgOutlinedIcon
                  sx={{
                    color: "#fff",
                    fontSize: "1.7rem",
                    background: "green",
                    p: "4px",
                    borderRadius: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "#222",
                  }}
                >
                  Phone :
                </Typography>
              </Box>
              <Typography
                sx={{
                  ml: "0.3rem",
                  mt: "0.4rem",
                  fontSize: "1rem",
                }}
                className="contact"
              >
                +91-804-717-8999, +91-804-717-8999
              </Typography>
            </Box> */}
            <Box>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
              >
                <EmailOutlinedIcon
                  sx={{
                    color: "#fff",
                    fontSize: "1.7rem",
                    background: "#de028a",
                    p: "4px",
                    borderRadius: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "#222",
                  }}
                >
                  Email :
                </Typography>
              </Box>
              <Typography
                sx={{
                  ml: "0.2rem",
                  mt: "0.4rem",
                  fontSize: "1rem",
                }}
                className="contact"
              >
                CerebraWave@gmail.com
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ p: { sm: 10, xs: 2 } }}>
          <ContactForm />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Contact;
