import React, { useEffect } from "react";
import consulting from "../images/ai.jpg";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import solutions from "../images/insightlab.webp";
import products from "../images/roo1.webp";
import main from "../images/ai.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Homesection3 = () => {
  useEffect(() => {
    Aos.init({ duration: 20000 });
  }, []);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ p: { xs: 2, sm: 8 } }}
        backgroundColor="#f5f7f9"
      >
        <div data-aos="fade-right">
          <img
            src={consulting}
            alt="consulting"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "100%",
              display: "block",
              margin: "auto",
            }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ p: { xs: 2, sm: 8 } }}
        backgroundColor="#f5f7f9"
      >
        <Stack display="flex" alignItems="center" justifyContent="center">
          <Typography fontWeight="300" fontSize="32px" color="black">
            Consulting
          </Typography>
          <Divider
            sx={{
              width: "180px",
              mt: "0.5rem",
              borderColor: "#ff4530",
              borderWidth: "2px",
              borderRadius: "4px",
            }}
          />
          <Typography
            mt={5}
            color="#747474"
            sx={{
              fontSize: "1.2rem",
              textAlign: "justify",
              lineHeight: "35px",
            }}
          >
           
Drawing upon decades of experience in technology consulting for numerous industry-leading firms, we offer a distinctive fusion of business acumen and technological proficiency. Our goal is to deliver strategic consulting solutions that empower businesses to embrace and adapt to emerging technologies.
          </Typography>
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{ p: { xs: 2, sm: 8 } }}
        backgroundColor="#f5f7f9"
      >
        <Stack display="flex" alignItems="center" justifyContent="center">
          <Typography fontWeight="300" fontSize="32px" color="black">
            Solutions
          </Typography>
          <Divider
            sx={{
              width: "160px",
              mt: "0.5rem",
              borderColor: "#ff4530",
              borderWidth: "2px",
              borderRadius: "4px",
            }}
          />
          <Typography
            mt={5}
            color="#747474"
            sx={{
              fontSize: "1.2rem",
              textAlign: "justify",
              lineHeight: "35px",
            }}
          >
           We've cultivated a robust competency in delivering end-to-end services, encompassing the development of groundbreaking technology solutions and seamless integration with existing technologies and business models. This holistic approach ensures that businesses can fully realize the proposed value outlined in our consulting services.
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ p: { xs: 2, sm: 8 } }}
        backgroundColor="#f5f7f9"
      >
        <div data-aos="fade-left">
          <img
            src={solutions}
            alt="solutions"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "100%",
              display: "block",
              margin: "auto",
            }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ p: { xs: 2, sm: 8 } }}
        backgroundColor="#f5f7f9"
      >
        <div data-aos="fade-right">
          <img
            src={products}
            alt="products"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "100%",
              display: "block",
              margin: "auto",
            }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ p: { xs: 2, sm: 8 } }}
        backgroundColor="#f5f7f9"
      >
        <Stack display="flex" alignItems="center" justifyContent="center">
          <Typography fontWeight="300" fontSize="32px" color="black">
            Products
          </Typography>
          <Divider
            sx={{
              width: "150px",
              mt: "0.5rem",
              borderColor: "#ff4530",
              borderWidth: "2px",
              borderRadius: "4px",
            }}
          />
          <Typography
            mt={5}
            color="#747474"
            sx={{
              fontSize: "1.2rem",
              textAlign: "justify",
              lineHeight: "35px",
            }}
          >
           We've engineered a comprehensive suite of products harnessing our proficiency in cutting-edge technologies such as artificial intelligence, machine learning, and robotic process automation. These solutions are designed to empower businesses to unlock value that is not only robust and validated but also cost-effective and effortless to implement.
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ p: { xs: 3, sm: 20 } }}
        style={{
          backgroundImage: `url(${main})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          height: "450px",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            background: "black",
            opacity: "0.7",
          }}
        />
        <Box
          zIndex="999"
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          justifyContent="center"
          position={"absolute"}
          gap={"0.3rem"}
        >
          <Typography
            fontWeight="300"
            sx={{ fontSize: { xs: "1.4rem", md: "1.7rem" } }}
            color="white"
          >
            The best way to be success
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1.8rem", md: "2.8rem" },
              color: "white",
              textAlign: "center",
            }}
          >
            Are you ready to succeed with us?
          </Typography>
          <Link
              to="/contact">
             <Button
            variant="contained"
            sx={{
              backgroundColor: "#ff4530",
              fontSize: "1.0rem",
              fontWeight: "light",
              padding: "15px 30px",
            }}
          >
            CONTACT US
          </Button>
              </Link>
        
        </Box>
      </Grid>
    </Grid>
  );
};

export default Homesection3;
