import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CerebraWaves from "../../images/CerebraWaves.jpeg";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SendIcon from "@mui/icons-material/Send";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Facebook, LinkedIn, WhatsApp } from "@mui/icons-material";
import PageLayout from "./PageLayout";
import { useState } from "react";

const iconList = [
  { icon: WhatsApp, path: "https://google.com", bgColor: "#04380d" },
  { icon: YouTubeIcon, path: "https://google.com", bgColor: "#8c010f" },
  { icon: InstagramIcon, path: "https://google.com", bgColor: "#a10249" },
  { icon: Facebook, path: "https://google.com", bgColor: "#253da8" },
  { icon: LinkedIn, path: "https://google.com", bgColor: "#1f1b70" },
  { icon: TwitterIcon, path: "https://google.com", bgColor: "#02cfd6" },
];

const heading = {
  color: "white",
  fontSize: "1.4rem",
  mb: "0.8rem",
  fontWeight: 500,
};
const content = {
  textAlign: "justify",
  color: "#cccccc",
  fontWeight: "400",
  fontSize: "0.8rem",
  flexWrap: "wrap",
  maxWidth: { md: "30vw", xs: "100vw" },
  display: "flex",
  alignItems: "center",
};
const Footer = () => {
  const [email, setEmail] = useState("");

  const handleMoveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSend = () => {
    try {
      //api with email
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageLayout
      styleProps={{
        p: { md: "3rem 4rem", xs: "2rem 1rem" },
        backgroundColor: "#2c2e37",
      }}
    >
      <Box
        md={4}
        sm={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ md: { flex: 0.6 }, xs: { flex: 1 } }}>
          <Typography sx={heading}>About</Typography>
          <Typography sx={content}>
            {" "}
            CerebraWave is a prominent global technology enterprise specializing in cutting-edge technologies, including Artificial Intelligence (AI), Machine Learning, Robotic Process Automation (RPA), Blockchain, and Internet of Things (IoT). Our core objective at CerebraWave is to empower businesses by enabling them to harness the full potential of disruptive technologies, thus ensuring their sustained competitiveness within the market.
          </Typography>
        </Box>
        <Box>
          <Typography sx={heading}>Programs</Typography>
          <Typography sx={content}>Corporate</Typography>
          <Typography sx={content}>One to One</Typography>
          <Typography sx={content}>Consulting</Typography>
        </Box>

        <Box>
          <Stack>
            <Typography sx={heading}>Services</Typography>
            <Typography sx={content}>Training</Typography>
            <Typography sx={content}>Coaching</Typography>
            <Typography sx={content}>Consulting</Typography>
          </Stack>
        </Box>

        <Box>
          <Typography sx={heading}>Contacts</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter your email"
              style={{
                padding: "0.4rem 0.6rem",
                flex: 0.7,
                height: "2.2rem",
                outline: "none",
                border: "none",
                fontSize: "0.9rem",
                fontWeight: 500,
                borderRadius: "  4px 0  0 4px",
              }}
            />
            <button
              onClick={handleSend}
              style={{
                height: "2.2rem",
                padding: "0.2rem 0.4rem",
                outline: "none",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#f79411",
                color: "white",
                borderRadius: " 0 4px 4px 0",
                cursor: "pointer",
              }}
            >
              <SendIcon />
            </button>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.4rem",
              m: "0.8rem 0rem",
            }}
          >
            {iconList?.map((item, idx) => (
              <Link key={idx} to={item?.path}>
                <Box
                  sx={{
                    display: "flex",
                    borderRadius: "50px",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "2rem",
                    width: "2rem",
                    backgroundColor: item.bgColor,
                  }}
                >
                  {
                    <item.icon
                      sx={{
                        fontSize: "1.4rem",
                        color: "white",
                      }}
                    />
                  }
                </Box>
              </Link>
            ))}
          </Box> */}
          {/* <Typography mt={1} sx={content}>
            <CallIcon
              sx={{
                fontSize: "1.2rem",
                mr: "4px",
                background: "white",
                color: "green",
                p: "0.1rem",
                borderRadius: "2px",
              }}
            />
            Mobile: +918738748983
          </Typography> */}
          <Typography mt={1} sx={{ ...content, m: "0.6rem 0" }}>
            <MailOutlineIcon
              sx={{
                fontSize: "1.2rem",
                mr: "4px",
                background: "white",
                color: "red",
                p: "0.1rem",
                borderRadius: "2px",
              }}
            />
            Email: CerebraWave@gmail.com
          </Typography>
          <Typography
            sx={{ ...content, fontWeight: "600", letterSpacing: "1px" }}
          >
            <LocationOnIcon
              sx={{
                fontSize: "1.2rem",
                mr: "4px",
                background: "white",
                color: "red",
                p: "0.1rem",
                borderRadius: "2px",
              }}
            />
            Address:{" "}
          </Typography>
          <Typography sx={{ ...content, mt: "0.4rem" }}>
            Atlantis liberty Square Building, No. 313, 2nd floor, Banashankari
            6th stage, Bangalore 560062
          </Typography>
        </Box>
      </Box>
      <Box sx={{ m: "2rem 0rem" }}>
        <Box
          sx={{
            height: "1px",
            width: "100%",
            background: "#cccccc",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <img
            src={CerebraWaves}
            height="150"
            width="330"
            alt="footer_img"
          />
        </Box>
        <Typography sx={content}>
          {" "}
          © Copyright 2017 - 2024 CerebraWaves | All Rights Reserved
        </Typography>

        <Box
          onClick={handleMoveToTop}
          sx={{
            height: "2.2rem",
            width: "2.2rem",
            backgroundColor: "purple",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            xs: {
              mt: "1rem",
            },
          }}
        >
          <ArrowUpwardIcon
            sx={{
              color: "white",
              height: "1.5rem",
              width: "1.5rem",
              border: "2px solid white",
              borderRadius: "50%",
            }}
          />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Footer;
