import React, { useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import abtech from "../images/abtech.mp4";
import { Link } from "react-router-dom";
import "../styles/HomeStyles.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Typed from "typed.js";
import airdraw1 from "../images/airdraw.jpeg"
import Homesection from "./Homesection";
import HomeSection2 from "./HomeSection2";
import Homesection3 from "./Homesection3";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
    // Initialize Typed.js
    const typed = new Typed("#typed-text", {
      strings: [
        "Specializing in innovative technologies",
        "Pioneering Artificial Intelligence (AI), Machine Learning, and Robotic Process Automation (RPA)",
        "Exploring Block Chain and Internet of Things (IoT) advancements",
        "CerebraWaves founders possess profound expertise in leading-edge technology",
        "Bringing over a decade of expertise in implementing smart city solutions for European Commission (EC) projects",
      ],
      typeSpeed: 50,
      backSpeed: 30,
      backDelay: 1000,
      startDelay: 500,
      loop: true,
    });

    return () => {
      // Clean up Typed.js on component unmount
      typed.destroy();
    };
  }, []);


  return (
    <>
    <Layout>
      <div className="home">
        <video autoPlay loop muted playsInline className="video-bg" data-aos="fade-in">
          <source src={abtech} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="headerContainer">
          <h1 data-aos="zoom-in-up">CerebraWave</h1>
          <p id="typed-text" data-aos="fade-up"></p>
        </div>
      </div>
   
    <Homesection/>
    <HomeSection2/>
    <Homesection3 />
    </Layout>
    </>
  );
};

export default Home;
