import React from "react";
import { Typography, Grid, Stack, Divider, Box } from "@mui/material";
import banner from "../images/home-banner.jpg";
import { Parallax } from "react-parallax";
import servicesImg from "../images/services1.png";

const HomeSection2 = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ p: { xs: 2, sm: 10 }, direction: { xs: "column", sm: "row" } }}
        backgroundColor="#f5f7f9"
      >
        <Stack display="flex" alignItems="center" justifyContent="center">
          <Typography fontWeight="300" fontSize="32px" color="black">
            At CerebraWaves
          </Typography>
          <Divider
            sx={{
              width: "310px",
              mt: "0.5rem",
              borderColor: "#ff4530",
              borderWidth: "2px",
              borderRadius: "4px",
            }}
          />
          <Typography
            mt={5}
            color="#747474"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "35px",
              p: { xs: "0.5rem" },
            }}
          >
          We are deeply committed to empowering businesses with revolutionary solutions through cutting-edge technologies. The CerebraWaves team excels in providing enterprise-grade solutions in Artificial Intelligence (AI), Robotic Process Automation (RPA), and Internet of Things (IoT). With decades of experience in technology consulting, we assist businesses in leveraging AI to enhance customer insights, automate processes, and drive cost-efficiency.
          </Typography>
        </Stack>
      </Grid>
      <Parallax bgImage={servicesImg} strength={600}>
        <Box
          sx={{
            height: { md: "450px", xs: "600px" },
            width: "100vw",
            p: { xs: "3rem 0rem", md: "3rem", lg: "3rem" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              background: "black",
              opacity: "0.8",
            }}
          ></Box>
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems="center"
            justifyContent="center"
            position={"absolute"}
            width={"100%"}
            sx={{
              p: { md: "3rem 0rem" },
            }}
          >
            <Typography fontWeight="300" fontSize="32px" color="white">
              Our Services
            </Typography>
            <Divider
              sx={{
                width: "210px",
                mt: "0.5rem",
                borderColor: "#ff4530",
                borderWidth: "2px",
                borderRadius: "4px",
              }}
            />
            <Typography
              mt={5}
              color="#c4c4c4"
              sx={{
                fontSize: "18px",
                textAlign: "justify",
                lineHeight: "35px",
                p: { md: "0rem 4rem", xs: "0rem 1rem" },
              }}
            >
             
CerebraWaves offers dependable and top-notch staffing solutions, providing you with the flexibility to bolster your workforce without the commitment of full-time hires. We help alleviate the burden on overloaded employees during crucial periods and ensure seamless project continuity. Unlike conventional staffing models, CerebraWaves assumes responsibility for the deliverables throughout the staffing engagement, ensuring timely delivery and surpassing client expectations.
            </Typography>
          </Box>
        </Box>
      </Parallax>
    </Grid>
  );
};

export default HomeSection2;
