import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isValid } from "../utils/helper";

const ContactForm = () => {
  const showToastMessage = (msg, msgType = "success") => {
    toast(msg, {
      type: msgType,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValid(formData)) {
      showToastMessage("Please enter all the fields!", "info");
      return;
    }

    setIsLoading(true);
    try {
      await axios.post("https://airdraw.onrender.com/sendmail", formData);
      showToastMessage("Your query has been submited successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.log(error.message);
      showToastMessage("Something went wrong, please try again!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #ebe8e9",
          borderRadius: "8px",
          padding: "20px",
          maxWidth: "600px",
        }}
      >
        <Stack>
          <Typography textAlign="center" fontSize="20px" className="contact">
            GET IN TOUCH
          </Typography>
          <Typography
            textAlign="center"
            mt={1}
            mb={1}
            color="#777"
            className="contact"
          >
            Please complete the form, and we will get back to you.
          </Typography>
        </Stack>
        <Divider />

        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{
              height: "2.5rem",
            }}
          />
          <TextField
            label="Phone Number"
            fullWidth
            margin="normal"
            variant="outlined"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            sx={{
              height: "2.5rem",
            }}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleChange}
            sx={{
              height: "2.5rem",
            }}
          />
          <TextField
            label="Message"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            variant="outlined"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send Message"}
          </Button>
        </form>
      </Box>
      <ToastContainer />
    </>
  );
};

export default ContactForm;
