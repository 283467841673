export const isValid = (formData) => {
  let valid = true;
  if (
    !formData.email ||
    !formData.name ||
    !formData.phone ||
    !formData.message
  ) {
    valid = false;
  }
  return valid;
};
